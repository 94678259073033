<template>
    <div>
        <CSTabBar :tabbar="tabBarList" :checkedTab="tabBarType" @changeTabBar="changeTabbar"></CSTabBar>
        <div class="filter-panel">
            <CSSelect style="margin-right: 10px; width: 185px;">
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    value-format="yyyy-MM-dd"
                    prefix-icon="el-icon-time"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0 15px 0;
                    height: 2px;
                    width: 20px;
                    margin-right: 0;
                    background-color: #999;
                "
            >
            </span>
            <CSSelect style="margin-left: 10px; width: 185px;">
                <el-date-picker
                    v-model="endDate"
                    type="date"
                    format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    value-format="yyyy-MM-dd"
                    prefix-icon="el-icon-time"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input
                type="text"
                class="cs-input"
                style="margin-right: 30px;"
                placeholder="搜索用户信息/消费码/商户信息"
                v-model="userInfo"
            />
            <button class="btn btn-primary" @click="getBillList()">查询</button>
        </div>

        <div class="result-panel">
            <template v-if="tabBarType === 1">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <p class="table-header-panel">
                            累计已付金额: {{ totalPaid || '-' }}元
                        </p>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">付款时间</th>
                            <th class="text-center">项目信息</th>
                            <th class="text-center">单价</th>
                            <th class="text-center">购买数量</th>
                            <th class="text-center">已付金额</th>
                            <th class="text-center">已退金额</th>
                            <th class="text-center">抽成比例</th>
                            <th class="text-center">抽成收益</th>
                            <th class="text-center">用户信息</th>
                            <th class="text-center">商户信息</th>
                            <th class="text-center">支付渠道</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="(bill, index) in billList" :key="index">
                            <td class="text-center date-td">
                                {{ bill.paymentTime || '-' }}
                            </td>
                            <td class="text-center">{{ bill.itemName }}</td>
                            <td class="text-center">
                                {{ bill.price ? bill.price + '元' : '-' }}
                            </td>
                            <td class="text-center">
                                {{ bill.butNum || '-' }}
                            </td>

                            <td class="text-center">
                                {{ bill.paymentMoney ? bill.paymentMoney + '元' : '-'  }}
                            </td>
                            <td class="text-center">
                                {{ bill.refundMoney ? bill.refundMoney + '元' : '-' }}
                            </td>
                            <td class="text-center">
                                {{ bill.commissionScale || '-' }}%
                            </td>
                            <td class="text-center">
                                {{ bill.commissionEarnings || '-' }}
                            </td>
                            <td class="text-center">
                                <div>
                                    <p style="margin-bottom: 0px;">{{ bill.userName }} 【{{ bill.userPhone || '-' }}】</p>
                                    <p style="margin-bottom: 0;">{{ bill.userCompany }}</p>
                                    <p style="margin: 0 0 0 0;" v-if="bill.userCompanyAddr">{{ bill.userCompanyAddr }}</p>
                                </div>
                            </td>
                            <td class="text-center">
                                <p style="margin-bottom: 10px;">{{ bill.merchantName }}</p>
                                <p style="margin-bottom: 0;">{{ bill.merchantContact }}</p>
                                <p style="margin: 10px 0 0 0;" v-if="bill.merchantPhone">{{ bill.merchantPhone }}</p>
                            </td>
                            <td>
                                {{
                                    PAY_CHANNEL_MAPPING[bill.payChannel] ||
                                    '-'
                                }}
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </template>
            <template v-if="tabBarType === 2">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <p class="table-header-panel">
                            累计已退金额: {{ totalEarning || '-' }} 元
                        </p>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">退款时间</th>
                            <th class="text-center">项目信息</th>
                            <th class="text-center">单价</th>
                            <th class="text-center">购买数量</th>
                            <th class="text-center">已付金额</th>
                            <th class="text-center">已退金额</th>
                            <th class="text-center">抽成比例</th>
                            <th class="text-center">抽成收益</th>
                            <th class="text-center">用户信息</th>
                            <th class="text-center">商户信息</th>
                            <th class="text-center">支付渠道</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="(bill, index) in billList" :key="index">
                            <td class="text-center date-td">
                                {{ bill.refundTime || '-' }}
                            </td>
                            <td class="text-center">{{ bill.itemName }}</td>
                            <td class="text-center">
                                {{ bill.price | realMoney }}
                            </td>
                            <td class="text-center">
                                {{ bill.butNum || '-' }}
                            </td>

                            <td class="text-center">
                                {{ bill.payMoney | realMoney }}
                            </td>
                            <td class="text-center">
                                {{ bill.refundMoney | realMoney }}
                            </td>
                            <td class="text-center">
                                <template v-if="bill.commissionScale">
                                    {{ bill.commissionScale }}%
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td class="text-center">
                                <template v-if="bill.commissionEarnings">
                                    {{ bill.commissionEarnings }}
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td class="text-center">
                                <div>
                                    <p style="margin-bottom: 0px;">{{ bill.userName }} 【{{ bill.userPhone || '-' }}】</p>
                                    <p style="margin-bottom: 0;">{{ bill.userCompany }}</p>
                                    <p style="margin: 0 0 0 0;" v-if="bill.userCompanyAddr">{{ bill.userCompanyAddr }}</p>
                                </div>
                            </td>
                            <td class="text-center">
                                <p style="margin-bottom: 10px;">{{ bill.merchantName }}</p>
                                <p style="margin-bottom: 0;">{{ bill.merchantContact }}</p>
                                <p style="margin: 10px 0 0 0;"  v-if="bill.merchantPhone">{{ bill.merchantPhone }}</p>
                            </td>
                            <td>
                                {{
                                    PAY_CHANNEL_MAPPING[bill.payChannel] ||
                                    '-'
                                }}
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </template>
            <Pagination name="Pagination" component="Pagination"></Pagination>
        </div>
        <ViewModal></ViewModal>

    </div>
</template>

<script>
import ViewModal from "@/components/ViewModal";
import Pagination from "@/components/Pagination.vue";
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import { queryVisitingServiceBillUrl } from "@/requestUrl";
import dayjs from "dayjs";
import { PAY_CHANNEL, PAY_CHANNEL_MAPPING } from "@/constant";
import CSTable from "@/components/common/CSTable";
// 服务提供方
const ORDER_STATE = {
    // 第三方
    DISANFANG: 1,
    // 物业
    WUYE: 2,
};
export default {
    name: "visitingServiceBill",
    components: {
        CSTable,
        ViewModal,
        Pagination,
        CSTabBar,
        CSSelect,
    },
    created(){
        window.addEventListener("keydown",this.getBillListDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getBillListDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getBillListDown);
    },
    data() {
        return {
            PAY_CHANNEL_MAPPING,
            startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
            userInfo: "",
            regionId: this.$vc.getCurrentRegion().communityId,
            tabBarType: 1,
            filterHeight: 0,
            tabBarList: {
                1: "付款",
                2: "已退款",
            },
            state: '',
            billList: [],
            //   累计已付金额
            totalPaid: 0,
            //   累计已退金额
            totalEarning: 0,
            orderStates: {
                [ORDER_STATE.DISANFANG]: "第三方",
                [ORDER_STATE.WUYE]: "物业",
            },
        };
    },
    filters: {
        realMoney(val) {
            return val ? val + "元" : '-';
        },
    },
    mounted() {
        this.getBillList();
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this.getBillList(_currentPage);
        });
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
        getBillListDown(e){
            if(e.keyCode == 13){
                this.getBillList();
            }
        },
        /**
         * 切换tabBar
         * @param {Number} type
         *  */
        changeTabbar(index) {
            this.tabBarType = index;
            this.getBillList();
        },
        //  获取退款记录
        getBillList(pageNo = 1, pageSize = 10) {
            const {
                startDate,
                endDate,
                userInfo,
                regionId,
                tabBarType: state,
            } = this;
            this.$fly
                .post(queryVisitingServiceBillUrl, {
                    startDate: startDate + " 00:00",
                    endDate: endDate + " 23:59",
                    userInfo,
                    regionId,
                    state,
                    pageNo,
                    pageSize,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    console.log(res.data)
                    if(pageNo === 1 && res.data.data){
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize: res.data.total ? pageSize : res.data.data.length,
                        });
                    }
                    this.billList = res.data?.data || [];
                    // console.log(res.data.data);
                    this.totalEarning = res.data.totalRefund;
                    console.log(this.totalEarning);
                    this.totalPaid = res.data.totalPaid;
                });
        },
        /**
         * 查看用户评价
         * @param {Object} logInfo log信息
         *  */
        lookEvaluate(logInfo) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                content: `
                    <div class="panel panel-default">
                        <div class="panel-body">
                            <p>${logInfo.score}星, ${logInfo.evaluateTime}</p>
                            <p>${logInfo.evaluate}</p>
                        </div>
                    </div>
                `,
            });
        },
        /**
         * 查看退款原因
         * @param {String} reason
         *  */
        lookRefundReason(reason) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                content: reason,
            });
        },
    },

};
</script>

<style lang="stylus" scoped>
input::-webkit-input-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-ms-input-placeholder
    color: #999;
</style>
